// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mineral-supplies-js": () => import("./../../../src/pages/mineral-supplies.js" /* webpackChunkName: "component---src-pages-mineral-supplies-js" */),
  "component---src-pages-property-developments-js": () => import("./../../../src/pages/property-developments.js" /* webpackChunkName: "component---src-pages-property-developments-js" */),
  "component---src-pages-rental-stocks-js": () => import("./../../../src/pages/rental-stocks.js" /* webpackChunkName: "component---src-pages-rental-stocks-js" */)
}

